import React from "react";
import { Flex, Text, FlexProps } from "@chakra-ui/react";
import { Image } from "@chakra-ui/image"

interface WalletScreenshotProps extends FlexProps {
  title: string;
  screenshot: string;
  bgImage: string;
}

const WalletScreenshot = ({
  title,
  screenshot,
  bgImage,
  ...props
}: WalletScreenshotProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      px={{ base: "5%", lg: "4.3rem" }}
      pt={{base: "2.5rem", lg: "4rem"}}
      bg={`url(${bgImage})`}
      backgroundSize="cover"
      borderWidth="1px"
      borderColor="secondary-grey-200"
      borderRadius="1.5rem"
      {...props}
    >
      <Text textStyle="h2" pb={{base: "2.5rem", lg: "4rem"}} color="contrast">{title}</Text>
      <Image src={screenshot} fallbackSrc={screenshot} alt={title} />
    </Flex>
  );
};

export default WalletScreenshot;
export type { WalletScreenshotProps };
