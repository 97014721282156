import React from "react";
import { Box, Flex, Link } from "@chakra-ui/react";
import {
  AppHeader,
  Hero,
  WalletScreenshotProps,
  WalletScreenshot,
  CButton,
  WalletDownload,
  BrowserOptionProps,
  BrowserOption,
  Slider,
  Layout,
} from "../components";
import BgScreenshot1 from "../assets/bg_screenshot1.png";
import BgScreenshot2 from "../assets/bg_screenshot2.png";
import BgScreenshot3 from "../assets/bg_screenshot3.png";
import BgScreenshot4 from "../assets/bg_screenshot4.png";
import BgScreenshot5 from "../assets/bg_screenshot5.png";
import Chrome from "../assets/chrome.svg";
import Opera from "../assets/opera.svg";
import Firefox from "../assets/firefox.svg";
import BgChrome from "../assets/bg_chrome.png";
import BgOpera from "../assets/bg_opera.png";
import BgFirefox from "../assets/bg_firefox.png";
import Screenshot1 from "../assets/screenshot1.png";
import Screenshot2 from "../assets/screenshot2.png";
import Screenshot3 from "../assets/screenshot3.png";
import Screenshot4 from "../assets/screenshot4.png";
import Screenshot5 from "../assets/screenshot5.png";
import Screenshot6 from "../assets/screenshot6.png";
import Screenshot7 from "../assets/screenshot7.png";

const walletScreenshotList: WalletScreenshotProps[] = [
  {
    bgImage: BgScreenshot1,
    screenshot: Screenshot1,
    title: "Hold tokens on the Internet Computer & Bitfinity EVM",
  },
  {
    bgImage: BgScreenshot2,
    screenshot: Screenshot2,
    title: "Collect & Explore your NFTs at one place",
  },
  {
    bgImage: BgScreenshot3,
    screenshot: Screenshot3,
    title: "Transact on the IC blockchain",
  },
  {
    bgImage: BgScreenshot4,
    screenshot: Screenshot4,
    title: "Deposit tokens directly into your wallet",
  },
  {
    bgImage: BgScreenshot2,
    screenshot: Screenshot5,
    title: "Save your favourite contacts",
  },
  {
    bgImage: BgScreenshot5,
    screenshot: Screenshot6,
    title: "Unlock with Seed phrase or Internet Identity",
  },
  {
    bgImage: BgScreenshot4,
    screenshot: Screenshot7,
    title: "Dark Mode Takes Center Stage!",
  },
];

const browserList: BrowserOptionProps[] = [
  {
    title: "Chrome",
    image: Chrome,
    badgeText: "Available Now",
    bgImage: BgChrome,
    badgeColor: "green",
  },
  {
    title: "Opera",
    image: Opera,
    badgeText: "Coming Soon",
    bgImage: BgOpera,
    badgeColor: "purple",
  },
  {
    title: "Firefox",
    image: Firefox,
    badgeText: "Coming Soon",
    bgImage: BgFirefox,
    badgeColor: "purple",
  },
];

const Main = () => {
  return (
    <Layout>
      <Hero />
      <Slider />
      <Box
        px={{ base: "5%", lg: "7%", "2xl": 0 }}
        maxWidth="1440px"
        margin="0 auto"
      >
        <Flex
          w="full"
          mb="3.375rem"
          flexDir={{ base: "column", lg: "row" }}
          gap="3.5rem"
        >
          <WalletScreenshot
            flexDir="column"
            flex={0.5}
            {...walletScreenshotList[0]}
          />
          <WalletScreenshot
            flexDir="column"
            flex={0.5}
            {...walletScreenshotList[1]}
          />
        </Flex>
        <Flex
          w="full"
          mb="3.375rem"
          flexDir={{ base: "column", lg: "row" }}
          gap="3.5rem"
        >
          <WalletScreenshot
            flexDir="column"
            flex={0.5}
            {...walletScreenshotList[2]}
          />
          <WalletScreenshot
            flexDir="column"
            flex={0.5}
            {...walletScreenshotList[3]}
          />
        </Flex>
        <Flex
          w="full"
          mb="3.375rem"
          flexDir={{ base: "column", lg: "row" }}
          gap="3.5rem"
        >
          <WalletScreenshot
            flexDir="column"
            flex={0.5}
            {...walletScreenshotList[4]}
          />
          <WalletScreenshot
            flexDir="column"
            flex={0.5}
            {...walletScreenshotList[5]}
          />
        </Flex>
        <Flex mb="4.25rem">
          <WalletScreenshot
            flexDir={{ base: "column", lg: "row" }}
            flex={1}
            textAlign={{ base: "center", lg: "start" }}
            sx={{
              "@media (min-width: 62em)": {
                p: { maxWidth: "45%" },
              },
            }}
            {...walletScreenshotList[6]}
          />
        </Flex>
        <Flex w="full" justifyContent="center" mb="8rem">
          <Link
            width={{ base: "100%", lg: "initial" }}
            isExternal
            href="https://chrome.google.com/webstore/detail/infinity-wallet/jnldfbidonfeldmalbflbmlebbipcnle"
            textTransform="uppercase"
            _hover={{}}
          >
            <CButton variant="primary" width="full">
              Try Bitfinity Wallet now
            </CButton>
          </Link>
        </Flex>
        <AppHeader
          title="Will be available on your favorite browsers"
          text="Turn your favorite browser into a Web3 enabled crypto wallet"
        />
        <Flex
          flexDir={{ base: "column", lg: "row" }}
          justifyContent="center"
          mb="7rem"
          gap="1.5rem"
        >
          {browserList.map((item, i) => (
            <BrowserOption {...item} key={i} flex={0.333} />
          ))}
        </Flex>
        <AppHeader
          title="Try Bitfinity Wallet now!"
          text="A wallet to store and manage NFTs, Tokens, and connect to dApps on the Internet Computer & Bitfinity EVM"
        />
        <Flex pb="8rem">
          <WalletDownload />
        </Flex>
      </Box>
    </Layout>
  );
};

export default Main;
