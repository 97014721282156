const Button = {
  baseStyle: {
    py: "1rem",
    textAlign: "center",
    borderRadius: "0.75rem",
    fontWeight: 500,
  },
  sizes: {
    sm: {
      height: "3rem",
    },
    lg: {
      height: "4rem",
    },
  },
  variants: {
    primary: {
      color: "white",
      backgroundColor: "primary",
    },
    secondary: {
      color: "white-600",
      backgroundColor: "secondary-grey-100",
    },
  },
  defaultProps: {
    variant: "primary",
    size: "lg",
  },
};

export default Button;
