import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { mode } from '@chakra-ui/theme-tools';
import Button from "./Button";

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: (props: any) => ({
      html: {
        base: {
          fontSize: "13px",
        },
        sm: {
          fontSize: "16px",
        },
      },
      body: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        textAlign: "center",
        color: mode('white', 'white')(props),
        bg: mode('#F2F2F2', '#00013A')(props),
      },
      ':root': {
        '--chakra-colors-white-600': mode('rgba(#FFFFFF, 0.6)', '#101925')(props),
      },
      a : {
        textDecoration: "none !important"
      },
      button : {
        textDecoration: "none !important"
      },
    }),
  },
  colors: {
    primary: "rgba(117, 39, 100, 1)",
    "secondary-grey-200": "rgba(138, 154, 185, 0.2)",
    green: "rgba(13, 169, 153, 1)",
    purple: "rgba(120, 61, 255, 1)",
  },
  light: {
    contrast: "#00013A",
    violet: "#FFFFFF",
    "secondary-grey": "#8A9AB9",
    "white-200": "#D3D3D3",
    "white-600": "#7383A4",
    secondary: "#00013A",
    "secondary-grey-100": "#0F1146",
    "secondary-black": "#F2F2F2",
  },
  dark: {
    contrast: "#FFFFFF",
    violet: "#0F1146",
    "secondary-grey": "#7383A4",
    "white-200": "rgba(255, 255, 255, 0.2)",
    "white-600": "rgba(255, 255, 255, 0.6)",
    secondary: "#444488",
    "secondary-grey-100": "rgba(138, 154, 185, 0.1)",
    "secondary-black": "rgba(0, 1, 58, 1)",
  },
  textStyles: {
    h1: {
      //H1-web
      fontWeight: 700,
      fontSize: {
        base: "2rem",
        lg: "3rem",
      },
      lineHeight: {
        base: "2.5rem",
        lg: "4rem",
      },
    },
    h2: {
      fontWeight: 600,
      fontSize: {
        base: "1.8rem",
        lg: "2.5rem",
      },
      lineHeight: {
        base: "2.5rem",
        lg: "3.5rem",
      },
    },
    h3: {
      fontWeight: 600,
      fontSize: "2rem",
      lineHeight: "2.5rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "2rem",
      lineHeight: "2.75rem",
    },
    h5: {
      //Body-text-web-big
      fontWeight: 500,
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.125rem",
      lineHeight: "2rem",
    },
    logo: {
      fontWeight: 600,
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
    },
    accordion: {
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: "1.25rem",
      letterSpacing: "0.02em",
    },
    accordionText: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      letterSpacing: "0.02em",
    },
    p: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: "1.25rem",
      letterSpacing: "0.02em",
    },
    link: {
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "1rem",
      letterSpacing: "0.02em",
    },
  },
  components: {
    Button,
  },
});

export default theme;
