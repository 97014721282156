import { Flex, Badge, Box, Text, FlexProps } from "@chakra-ui/react";
import { Image } from "@chakra-ui/image";

interface BrowserOptionProps extends FlexProps {
  title: string;
  image: string;
  badgeText: string;
  bgImage: string;
  badgeColor: string;
}

const BrowserOption = ({
  title,
  image,
  badgeText,
  bgImage,
  badgeColor,
  ...props
}: BrowserOptionProps) => {
  return (
    <Flex
      position="relative"
      alignItems="center"
      flexDir="column"
      borderWidth="1px"
      borderColor="secondary-grey-200"
      borderRadius="1.5rem"
      pt="3.125rem"
      bgImage={`url(${bgImage})`}
      backgroundSize="cover"
      {...props}
    >
      <Image src={image} fallbackSrc={image} alt={title} />
      <Text textStyle="h6" mt="2rem" mb="2rem">
        {title}
      </Text>
      <Box
        bg={badgeColor}
        borderRadius="0.5rem"
        padding="0.4rem 0.8rem"
        position="absolute"
        bottom="-1.2rem"
      >
        <Badge bg="transparent" fontSize="1rem" color="white">
          {badgeText}
        </Badge>
      </Box>
    </Flex>
  );
};

export default BrowserOption;
export type { BrowserOptionProps };
