import { Button } from "@chakra-ui/react";

const lineStyle = {
  borderColor: "var(--chakra-colors-contrast)",
  borderWidth: "1.5px",
  borderRadius: "1.5rem",
  width: "1.2rem"
}

interface MenuButtonProps {
  type: string;
  onClickMenu: () => void;
}

const MenuButton = ({ type, onClickMenu, ...props }: MenuButtonProps) => {
  return (
    <Button
      height="3rem"
      padding="0.8rem"
      backgroundColor="secondary-grey-200"
      borderRadius="0.5rem"
      flexDir="column"
      justifyContent="space-around"
      alignItems="flex-start"
      onClick={onClickMenu}
      {...props}
    >
      {type === "menu" && (
        <>
          <hr
            style={{
              ...lineStyle,
              width: "1rem",
            }}
          />
          <hr
            style={{
              ...lineStyle,
              width: "0.8rem",
            }}
          />
          <hr
            style={{
              ...lineStyle,
              width: "0.5rem",
            }}
          />
        </>
      )}
      {type === "close" && (
        <div>
          <hr style={{ ...lineStyle, transform: "rotateZ(45deg)" }} />
          <hr style={{ ...lineStyle, marginTop: '-3px', transform: "rotateZ(-45deg)" }} />
        </div>
      )}
    </Button>
  );
};
export default MenuButton;
