import * as React from "react";
import {
  HStack,
  Box,
  useColorMode,
  useColorModeValue,
  IconButtonProps,
} from "@chakra-ui/react";
import { ReactComponent as LightModeIcon } from "./assets/icons/light_mode.svg";
import { ReactComponent as LightModeActiveIcon } from "./assets/icons/light_mode_active.svg";
import { ReactComponent as DarkModeIcon } from "./assets/icons/dark_mode.svg";
import { ReactComponent as DarkModeActiveIcon } from "./assets/icons/dark_mode_active.svg";

type ColorModeSwitcherProps = Omit<IconButtonProps, "aria-label">;

export const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = (props) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const SwitchIconLight = useColorModeValue(LightModeActiveIcon, LightModeIcon);
  const SwitchIconDark = useColorModeValue(DarkModeIcon, DarkModeActiveIcon);

  return (
    <HStack
      justify="center"
      my="4"
      mx="4"
      p="1"
      gap="1"
      borderRadius="40px"
      bg="secondary"
      cursor="pointer"
    >
      <Box
        w="50%"
        borderRadius="10px"
        bg={colorMode === "dark" ? "transparent" : "initial"}
        borderColor={colorMode === "dark" ? "transparent" : "primary"}
        aria-label={`Switch to light mode`}
        onClick={toggleColorMode}
      >
        <SwitchIconLight />
      </Box>
      <Box
        w="50%"
        borderRadius="10px"
        bg={colorMode === "dark" ? "grey.700" : ""}
        borderColor={colorMode === "dark" ? "grey.800" : "transparent"}
        aria-label={`Switch to dark mode`}
        onClick={toggleColorMode}
      >
        <SwitchIconDark />
      </Box>
    </HStack>
  );
};
