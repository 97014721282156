import {
  Box,
  Flex,
  Text,
  Divider,
  Link,
  HStack,
  LinkProps,
} from "@chakra-ui/react";
import Navbar from "../Navbar";
import { ReactComponent as TelegramIcon } from "../../assets/icons/telegram.svg";
import { ReactComponent as DiscordIcon } from "../../assets/icons/discord.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter.svg";
import { ReactComponent as MediumIcon } from "../../assets/icons/medium.svg";

const SocialLink = ({ children, ...props }: LinkProps) => {
  return (
    <Link
      sx={{ path: { fill: "#8A9AB9" } }}
      _hover={{
        path: { fill: "url(#paint0_linear_5143_5404)" },
      }}
      {...props}
    >
      {children}
    </Link>
  );
};

const Footer = () => {
  return (
    <Box px={{ base: "5%", lg: "7%", "2xl": 0 }} bg="secondary-grey-100">
      <Box maxWidth="1440px" margin="0 auto" pt="3rem" pb="2rem">
        <Navbar isHeader={false} />
        <Divider color="secondary-grey-200" opacity="0.2" />
        <Flex
          pt="3rem"
          pb="3.5rem"
          flexDir={{ base: "column", lg: "row" }}
          justifyContent="space-between"
          align="center"
          w="full"
        >
          <Flex alignItems="center" gap={2}>
            <Text textStyle="p" color="secondary-grey">
              {`Astari © ${new Date(Date.now()).getFullYear()}.`}
            </Text>
            <Text>
              <Link
                href="https://infinityswap-docs-wallet.web.app/disclaimer/"
                target="_blank"
              >
                Disclaimer
              </Link>{" "}
              <Text color="secondary-grey" as="span">
                &{" "}
              </Text>
              <Link
                href="https://infinityswap-docs-wallet.web.app/privacy/"
                target="_blank"
              >
                Privacy
              </Link>
            </Text>
          </Flex>
          <Flex
            gap={10}
            justifyContent={{ base: "center", lg: "space-between" }}
            mt={{ base: 8, lg: 0 }}
            align="center"
          >
            <HStack
              width={{ base: "100%", sm: "90%", md: "80%", lg: "fit-content" }}
              align="center"
              justify={{ base: "space-between", lg: "center" }}
              spacing={4}
            >
              <SocialLink href="https://twitter.com/bitfinitynet">
                <TwitterIcon />
              </SocialLink>
              <SocialLink href="https://t.me/infinityswapofficial">
                <TelegramIcon />
              </SocialLink>
              <SocialLink href="https://discord.gg/Bitfinity">
                <DiscordIcon />
              </SocialLink>
              <SocialLink href="https://medium.com/@infinityswap">
                <MediumIcon />
              </SocialLink>
            </HStack>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Footer;
