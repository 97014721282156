import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { ReactComponent as LogoIcon } from "../../assets/logo.svg";

const Logo = ({color}: {color: string}) => {
  return (
    <Flex flexDir="row" alignItems="center">
      <LogoIcon />
      <Text ml="1rem" textStyle="logo" color={color}>
        Bitfinity Wallet
      </Text>
    </Flex>
  );
};

export default Logo;
