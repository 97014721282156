import { Flex, Text } from "@chakra-ui/react";

const AppHeader = ({ title, text }: { title: string; text: string }) => {
  return (
    <Flex flexDir="column" px={{ base: "5%", lg: "10%" }}>
      <Text textStyle="h4" mb="1.5rem" color="contrast">
        {title}
      </Text>
      <Text textStyle="h5" color="white-600" mb="4.5rem">
        {text}
      </Text>
    </Flex>
  );
};

export default AppHeader;
