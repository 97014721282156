import { HStack, Link, Text, VStack, useColorMode } from "@chakra-ui/react";
import CButton from "../CButton";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";

interface MobileMenuProps {
  navLinks: { title: string; href: string; isNotExternal?: boolean }[];
  onClose: () => void;
}

const MobileMenu = ({ navLinks, onClose }: MobileMenuProps) => {
  const { colorMode } = useColorMode();
  return (
    <VStack
      bg="secondary-black"
      position="fixed"
      top="12vh"
      left="0"
      width="100vw"
      height="88vh"
      justify="space-between"
      zIndex="100"
      borderTop="1px solid"
      borderColor="white-200"
      // onClick={onClose}
    >
      <VStack align="flex-start" width="full">
        {navLinks.map((link) => (
          <Link
            key={link.href}
            isExternal={!link?.isNotExternal}
            href={link.href}
            width="full"
            borderBottom="1px solid"
            borderColor="white-200"
            textAlign="start"
          >
            <CButton variant="p" color="contrast" my="0.5rem">
              {link.title}
            </CButton>
          </Link>
        ))}
        <HStack
          width="full"
          borderBottom="1px solid"
          borderColor="white-200"
          py="1rem"
          px="1.5rem"
          mt="0.5rem"
          gap="4"
        >
          <HStack width="full" py="0.5rem" justify="space-between">
            <Text textStyle="h6" fontWeight="500" color="contrast">
              Theme
            </Text>
            <Text textStyle="link" color="secondary-grey">{colorMode === "dark" ? "NIGHT" : "DAY"}</Text>
          </HStack>
          <ColorModeSwitcher />
        </HStack>
      </VStack>
      <VStack
        align="center"
        spacing={4}
        padding={8}
        width="100%"
        position="absolute"
        bottom="0"
      >
        <CButton width="full" background="primary" color="white">
          Download Now
        </CButton>
      </VStack>
    </VStack>
  );
};
export default MobileMenu;
