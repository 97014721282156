import { Flex, Text, Link } from "@chakra-ui/react";
import { Image } from "@chakra-ui/image";
import CButton from "../CButton";
import BgWalletDownload from "../../assets/bg_wallet_download.png";
import BgWalletDownloadMobile from "../../assets/bg_wallet_download_mobile.png";
import WalletDownloadImage from "../../assets/wallet_download.png";
import WalletDownloadImageMobile from "../../assets/wallet_download_mobile.png";

const WalletDownload = () => {
  return (
    <Flex
      w="full"
      position="relative"
      borderRadius="1.5rem"
      backdropFilter="blur(2rem)"
      flexDir={{ base: "column-reverse", lg: "row" }}
      backgroundSize="cover"
      bgImg={{
        base: `url(${BgWalletDownloadMobile})`,
        lg: `url(${BgWalletDownload})`,
      }}
      overflow="hidden"
      height={{ base: "unset", lg: "440px" }}
      maxHeight={{ base: "unset", lg: "440px" }}
    >
      <Flex
        flexDir="column"
        justify="center"
        padding={{
          base: "1.5rem",
          lg: "4rem 0rem 4rem 4rem ",
          xl: "6rem 4rem 6rem",
        }}
        w={{ base: "100%", lg: "605px" }}
        textAlign={{ base: "center", lg: "start" }}
        zIndex="10"
      >
        <Text
          textStyle="h4"
          mb={{ base: "1.5rem", lg: "2.5rem" }}
          mt={{ base: "3rem", lg: 0 }}
          textAlign={{ base: "center", lg: "left" }}
          color="contrast"
        >
          Bitfinity Wallet has everything you need to navigate the Web3 Ecosystem
        </Text>
        <Link
          isExternal
          href="https://chrome.google.com/webstore/detail/infinity-wallet/jnldfbidonfeldmalbflbmlebbipcnle"
          textTransform="uppercase"
          _hover={{}}
        >
          <CButton width={{ base: "full", lg: "initial" }}>
            Download Now
          </CButton>
        </Link>
      </Flex>
      <Image
        display={{ base: "none", lg: "block" }}
        position="absolute"
        right={0}
        bottom={0}
        height="440px"
        width="965px"
        src={WalletDownloadImage}
        fallbackSrc={WalletDownloadImage}
        alt="wallet graphic"
      />
      <Image
        display={{ base: "block", lg: "none" }}
        width="full"
        src={WalletDownloadImageMobile}
        fallbackSrc={WalletDownloadImageMobile}
        alt="wallet graphic"
      />
    </Flex>
  );
};

export default WalletDownload;
