import {
  useColorMode,
  ThemeProvider,
  useTheme,
  ThemeComponentProps,
} from '@chakra-ui/react';

interface ColorModeThemeProviderProps
  extends Omit<ThemeComponentProps, 'colorMode' | 'theme'> {}

const ColorModeThemeProvider = (props: ColorModeThemeProviderProps) => {
  const { colorMode } = useColorMode();
  const theme = useTheme();

  return (
    <ThemeProvider
      {...props}
      theme={{
        ...theme,
        colors: { ...theme.colors, ...theme[colorMode] },
      }}
    >{props.children}</ThemeProvider>
  );
};

export default ColorModeThemeProvider;
