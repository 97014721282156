import {
  AppHeader,
  Layout,
  CustomAccordion,
  CustomAccordionProps,
} from "../components";
import { Accordion, Box, Flex, Text } from "@chakra-ui/react";
import data from "./data.json";

const FAQPage = () => {
  const {
    gettingStarted,
    manageDigitalAssets,
    payments,
    security,
    troubleshooting,
    additional,
  } = data;

  return (
    <Layout>
      <Box
        px={{ base: "5%", lg: "7%", "2xl": 0 }}
        py="5%"
        maxWidth="1440px"
        margin="0 auto"
      >
        <AppHeader
          title="Frequently Asked Questions"
          text="Have questions? We are here to help."
        />
        <Flex justifyContent="center" mb="7rem" gap="1.5rem">
          <Accordion
            allowMultiple
            width={{ base: "90%", lg: "60%" }}
            maxWidth="800px"
          >
            <Text textStyle="h6" mb={6} textAlign="start" color="contrast">
              Getting started
            </Text>
            {gettingStarted.map((item: CustomAccordionProps, i) => (
              <CustomAccordion
                key={i}
                question={item.question}
                answer={item.answer}
                link={item.link}
                answer1={item?.answer1}
                link1={item?.link1}
              />
            ))}
          </Accordion>
        </Flex>
        <Flex justifyContent="center" mb="7rem" gap="1.5rem">
          <Accordion
            allowMultiple
            width={{ base: "90%", lg: "60%" }}
            maxWidth="800px"
          >
            <Text textStyle="h6" mb={6} textAlign="start" color="contrast">
              Manage my digital assets
            </Text>
            {manageDigitalAssets.map((item: CustomAccordionProps, i) => (
              <CustomAccordion
                key={i}
                question={item.question}
                answer={item.answer}
                link={item.link}
                answer1={item?.answer1}
                link1={item?.link1}
              />
            ))}
          </Accordion>
        </Flex>
        <Flex justifyContent="center" mb="7rem" gap="1.5rem">
          <Accordion
            allowMultiple
            width={{ base: "90%", lg: "60%" }}
            maxWidth="800px"
          >
            <Text textStyle="h6" mb={6} textAlign="start" color="contrast">
              Payments
            </Text>
            {payments.map((item: CustomAccordionProps, i) => (
              <CustomAccordion
                key={i}
                question={item.question}
                answer={item.answer}
                link={item.link}
                answer1={item?.answer1}
                link1={item?.link1}
              />
            ))}
          </Accordion>
        </Flex>
        <Flex justifyContent="center" mb="7rem" gap="1.5rem">
          <Accordion
            allowMultiple
            width={{ base: "90%", lg: "60%" }}
            maxWidth="800px"
          >
            <Text textStyle="h6" mb={6} textAlign="start" color="contrast">
              Security
            </Text>
            {security.map((item: CustomAccordionProps, i) => (
              <CustomAccordion
                key={i}
                question={item.question}
                answer={item.answer}
                link={item.link}
                answer1={item?.answer1}
                link1={item?.link1}
              />
            ))}
          </Accordion>
        </Flex>
        <Flex justifyContent="center" mb="7rem" gap="1.5rem">
          <Accordion
            allowMultiple
            width={{ base: "90%", lg: "60%" }}
            maxWidth="800px"
          >
            <Text textStyle="h6" mb={6} textAlign="start" color="contrast">
              Troubleshooting: Support FAQs
            </Text>
            {troubleshooting.map((item: CustomAccordionProps, i) => (
              <CustomAccordion
                key={i}
                question={item.question}
                answer={item.answer}
                link={item.link}
                answer1={item?.answer1}
                link1={item?.link1}
                answer2={item?.answer2}
              />
            ))}
          </Accordion>
        </Flex>
        <Flex justifyContent="center" mb="7rem" gap="1.5rem">
          <Accordion
            allowMultiple
            width={{ base: "90%", lg: "60%" }}
            maxWidth="800px"
          >
            <Text textStyle="h6" mb={6} textAlign="start" color="contrast">
              Additional Support & Content
            </Text>
            {additional.map((item: CustomAccordionProps, i) => (
              <CustomAccordion
                key={i}
                question={item.question}
                answer={item.answer}
                link={item.link}
                answer1={item?.answer1}
                link1={item?.link1}
              />
            ))}
          </Accordion>
        </Flex>
      </Box>
    </Layout>
  );
};
export default FAQPage;
