import React from "react";
import { Button, Flex, Box, Text, Link } from "@chakra-ui/react";
import { Image } from "@chakra-ui/image";
import CButton from "../CButton";
import HeroImage from "../../assets/hero_image.png";
import HeroImageBg from "../../assets/hero_image_bg.png";
import SquaresBg from "../../assets/squares_bg.png";
import HeroLazyImage from "../../assets/hero_lazy.png";
import { ReactComponent as ChromeIcon } from "../../assets/chrome.svg";

const Hero = () => {
  return (
    <Flex
      flexDir={{ base: "column-reverse", lg: "row" }}
      pt="5%"
      pb={{ base: "5%", lg: "10%" }}
      px={{ base: "5%", lg: "7%", "2xl": 0 }}
      justifyContent="space-between"
      alignItems="center"
      backgroundImage={SquaresBg}
      backgroundSize="cover"
      position="relative"
      maxWidth="1440px"
      margin="0 auto"
      overflow="hidden"
    >
      <Box width={{ base: "100%", lg: "40%" }}>
        <Text
          textStyle="h1"
          mb="1.5rem"
          textAlign={{ base: "center", lg: "left" }}
          color="contrast"
        >
          Your Crypto & NFT Wallet for the IC & Bitfinity EVM.
        </Text>
        <Text
          textStyle="h5"
          color="white-600"
          mb="2.5rem"
          textAlign={{ base: "center", lg: "left" }}
        >
          A wallet to store and manage NFTs, Tokens, and connect to dApps on the
          Internet Computer & Bitfinity EVM
        </Text>
        <Flex flexDir={{ base: "column", lg: "row" }}>
          <Link
            isExternal
            mr={{ base: 0, lg: "1.5rem" }}
            mb={{ base: "1rem", lg: 0 }}
            href="https://chrome.google.com/webstore/detail/infinity-wallet/jnldfbidonfeldmalbflbmlebbipcnle"
            textTransform="uppercase"
            _hover={{}}
          >
            <CButton variant="primary" width="full">
              Download Now
            </CButton>
          </Link>
          <Link
            isExternal
            href="https://chrome.google.com/webstore/detail/infinity-wallet/jnldfbidonfeldmalbflbmlebbipcnle"
            _hover={{}}
          >
            <Button
              leftIcon={<ChromeIcon width={24} height={24} />}
              variant="secondary"
              width="full"
            >
              Available on Chrome Store
            </Button>
          </Link>
        </Flex>
      </Box>
      <Box width={{ base: "90%", lg: "35%" }} zIndex="1">
        <Image
          src={HeroImage}
          fallbackSrc={HeroLazyImage}
          alt="infinity-wallet-image"
          w="full"
          maxWidth="100%"
        />
      </Box>
      <Box
        position="absolute"
        width={{base: "150%", lg: "70%"}}
        height={{base: "80%", lg: "100%"}}
        right={{base: "-30%", lg: "-12%"}}
        top="0"
        backgroundImage={HeroImageBg}
        backgroundSize="cover"
        zIndex="0"
      ></Box>
    </Flex>
  );
};

export default Hero;
