import { ForwardedRef, forwardRef } from "react";
import { Button, ButtonProps, keyframes } from "@chakra-ui/react";

const glowing = keyframes`
0% { background-position: 0 0; }
50% { background-position: 400% 0; }
100% { background-position: 0 0; }
`;

const hoverStyles = {
  position: "relative",
  background:
    "linear-gradient(116.45deg, #CD478F 16.61%, #FFFFFF 32.26%, #7230FF 44.83%, #009BFF 60.78%, #FFFFFF 83.39%);",
  backgroundSize: "400%",
  zIndex: 2,
  animation: `${glowing} 20s linear infinite`,
  color: "var(--chakra-colors-contrast)",
  ":before": {
    display: "block",
    position: "absolute",
    content: '" "',
    width: "calc(100% - 4px)",
    height: "calc(100% - 4px)",
    borderRadius: ".6rem",
    backgroundColor: "secondary-black",
    zIndex: -1,
    top: "2px",
    left: "2px",
    filter: "blur(1px)",
  },
};

const CButton = forwardRef(
  (
    { children, ...props }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <Button
        {...props}
        ref={ref}
        _hover={hoverStyles}
        _active={{}}
        _focus={{}}
      >
        {children}
      </Button>
    );
  }
);

export default CButton;
