import { useState, useEffect } from "react";
import { Box, Button, Flex, Link, FlexProps, keyframes } from "@chakra-ui/react";
import { CButton, Logo, MenuButton, MobileMenu } from "../";
import { ColorModeSwitcher } from "../../ColorModeSwitcher";

const glowing = keyframes`
0% { background-position: 0 0; }
50% { background-position: 400% 0; }
100% { background-position: 0 0; }
`;

const hoverStyles = {
  position: "relative",
  zIndex: 2,
  ":before": {
    textDecorationColor: "transparent !important",
    display: "block",
    position: "absolute",
    content: '" "',
    width: "100%",
    height: "4px",
    backgroundImage:
      "linear-gradient(116.45deg, #CD478F 16.61%, #FFFFFF 32.26%, #7230FF 44.83%, #009BFF 60.78%, #FFFFFF 83.39%)",
    backgroundSize: "200%",
    animation: `${glowing} 20s linear infinite`,
    zIndex: -1,
    bottom: "0",
    left: 0,
  }
};

interface NavbarProps extends FlexProps {
  isHeader: boolean;
}

const navLinks = [
  {
    title: "Blog",
    href: "https://www.blog.bitfinity.network/",
  },
  {
    title: "Community",
    href: "https://discord.gg/Bitfinity",
  },
  {
    title: "Docs",
    href: "https://infinityswap-docs-wallet.web.app/",
  },
  {
    title: "Twitter",
    href: "https://twitter.com/bitfinitynet",
  },
  {
    title: "FAQ",
    href: "/faq",
    isNotExternal: true
  },
];

const Navbar = ({ isHeader, ...props }: NavbarProps) => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!document.body) {
      return;
    }
    document.body.style.overflowY = mobileMenuVisible ? "hidden" : "scroll";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, [mobileMenuVisible]);

  return (
    <Flex
      flexDir={isHeader ? { base: "row" } : { base: "column", lg: "row" }}
      justifyContent="space-between"
      py="1.5rem"
      alignItems={
        isHeader ? { base: "flex-start", lg: "center" } : { base: "center" }
      }
      {...props}
    >
      <Link href="/">
        <Logo color={isHeader ? "contrast" : "white"}/>
      </Link>
      <Box display={isHeader ? { base: "flex", lg: "none" } : { base: "none" }}>
        <MenuButton
          type={mobileMenuVisible ? "close" : "menu"}
          onClickMenu={() => setMobileMenuVisible((a) => !a)}
        />
      </Box>
      <Flex
        flexDir={{ base: "column", lg: "row" }}
        display={isHeader ? { base: "none", lg: "flex" } : { base: "flex" }}
        alignItems="center"
        mt={{ base: "1rem", lg: 0 }}
        mb={{ base: "2rem", lg: 0 }}
      >
        {navLinks.map((link) => (
          <Link
            key={link.href}
            isExternal={!link.isNotExternal}
            href={link.href}
            textStyle="link"
            _hover={hoverStyles}
            mr={isHeader ? { base: 0, lg: "2.75rem" } : {}}
            ml={!isHeader ? { base: 0, lg: "2.75rem" } : {}}
            mt={{ base: "1.5rem", lg: 0 }}
          >
            <Button
              variant="link"
              color={isHeader ? "contrast" : "white"}
              textAlign="right"
              height="3rem"
            >
              {link.title}
            </Button>
          </Link>
        ))}
        {isHeader && (
          <>
          <Link
            isExternal
            href="https://chrome.google.com/webstore/detail/infinity-wallet/jnldfbidonfeldmalbflbmlebbipcnle"
            textTransform="uppercase"
          >
            <CButton variant="primary" size="sm">
              Download Now
            </CButton>
          </Link>
          <ColorModeSwitcher />
          </>
        )}
      </Flex>
      {mobileMenuVisible && isHeader && (
        <MobileMenu
          navLinks={navLinks}
          onClose={() => setMobileMenuVisible(false)}
        />
      )}
    </Flex>
  );
};

export default Navbar;
