import React from "react";
import { ChakraProvider, useColorMode } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import defaultTheme from "./theme";
import ColorModeThemeProvider from "./theme/ColorModeThemeProvider";
import { Fonts } from "./components";
import Main from "./pages";
import FAQ from "./pages/Faq";

function App() {
  const { colorMode } = useColorMode();

  const theme = {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      ...defaultTheme.colors[colorMode],
    },
  };
  return (
    <ChakraProvider theme={theme}>
      <ColorModeThemeProvider>
        <Fonts />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/faq" element={<FAQ />} />
        </Routes>
      </ColorModeThemeProvider>
    </ChakraProvider>
  );
}

export default App;
