import { useState, useEffect } from "react";
import { HStack, Box, Text, keyframes } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ReactComponent as AsteriskIcon } from "../../assets/icons/asterisk.svg";
import { ReactComponent as DiamondIcon } from "../../assets/icons/diamond.svg";
import { ReactComponent as StairsIcon } from "../../assets/icons/stairs.svg";
import { ReactComponent as SoftStarIcon } from "../../assets/icons/soft_star.svg";
import { ReactComponent as ButterflyIcon } from "../../assets/icons/butterfly.svg";
import { ReactComponent as SoftFlowerIcon } from "../../assets/icons/soft_flower.svg";

const sliderData = [
  {
    icon: <DiamondIcon />,
    title: " Transact on the IC blockchain",
  },
  {
    icon: <SoftStarIcon />,
    title: "Hold ICRC tokens on IC, including ckETH and ckBTC",
  },
  {
    icon: <AsteriskIcon />,
    title: "Hold ERC20 tokens on the Bitfinity EVM",
  },
  {
    icon: <DiamondIcon />,
    title: "20,000+ Downloads",
  },
  {
    icon: <StairsIcon />,
    title: "Deposit, transfer, and store tokens",
  },
  {
    icon: <ButterflyIcon />,
    title: "Collect & explore NFTs",
  },
  {
    icon: <SoftFlowerIcon />,
    title: "Access your favourite Dapps",
  },
];

const Slider = () => {
  const [sliderWidth, setSliderWidth] = useState<any>(0);
  
  useEffect(() => {
    const element = document.querySelector("#slider");
    if (element?.clientWidth && element.clientWidth > 0) {
      setSliderWidth(element.clientWidth);
    }
  }, []);

  const animationKeyframes = keyframes`
    0% { transform: translateX(0%);}
    50% {transform: translateX(-${sliderWidth - window?.innerWidth}px);}
    100% { transform: translateX(0%)}
  `;

  const animation = `${animationKeyframes} 100s linear infinite`;
  return (
    <Box
      width="full"
      overflow="hidden"
      bgGradient="linear-gradient(90deg, rgba(120, 61, 255, 0.016) 0%, rgba(120, 61, 255, 0.05) 54.17%, rgba(120, 61, 255, 0.016) 100%)"
      pt={{ base: "1.5rem", lg: "2.5rem" }}
      pb={{ base: "1.5rem", lg: "2.5rem" }}
      mb="10%"
    >
      <HStack
        id="slider"
        as={motion.div}
        width="max-content"
        animation={animation}
        gap="2rem"
      >
        {[...sliderData, ...sliderData].map((slider, i) => (
          <HStack key={i} gap="2rem">
            {slider.icon}
            <Text textStyle="h5" color="white-600">
              {slider.title}
            </Text>
          </HStack>
        ))}
      </HStack>
    </Box>
  );
};

export default Slider;
