import { Box, BoxProps } from "@chakra-ui/react";
import { Navbar, Footer } from "..";

const Layout = ({ children }: BoxProps) => {
  return (
    <Box bg="secondary-black">
      <Box
        px={{ base: "5%", lg: "7%", "2xl": 0 }}
        maxWidth="1440px"
        margin="0 auto"
      >
        <Navbar isHeader />
      </Box>
      {children}
      <Footer />
    </Box>
  );
};

export default Layout;
