import {
  Box,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
  Link,
} from "@chakra-ui/react";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";

export interface CustomAccordionProps {
  question: string;
  answer: string;
  answer1?: string;
  answer2?: string;
  link?: {
    title?: string;
    href?: string;
  };
  link1?: {
    title?: string;
    href?: string;
  };
}

const CustomAccordion = ({
  question,
  answer,
  link,
  answer1,
  link1,
  answer2,
}: CustomAccordionProps) => {
  return (
    <AccordionItem
      border="none"
      borderRadius="12px"
      backgroundColor="violet"
      mb={4}
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton padding={6}>
            <Box flex="1" textAlign="left">
              <Text textStyle="accordion" color="contrast">
                {question}
              </Text>
            </Box>
            {isExpanded ? (
              <MinusIcon fontSize="12px" />
            ) : (
              <AddIcon fontSize="12px" />
            )}
          </AccordionButton>
          <AccordionPanel px="1.5rem" textAlign="start">
            <Text textStyle="accordionText" color="secondary-grey" as="span">
              {answer}
            </Text>
            {link && (
              <Link
                isExternal
                href={link?.href}
                textStyle="link"
                color="white-600"
              >
                {link?.title || link?.href}
              </Link>
            )}
            <Text textStyle="accordionText" color="secondary-grey" as="span">
              {answer1 && answer1}
            </Text>
            {link1 && (
              <Link
                isExternal
                href={link1?.href}
                textStyle="link"
                color="white-600"
              >
                {link1?.title || link1?.href}
              </Link>
            )}
            <Text textStyle="accordionText" color="secondary-grey" as="span">
              {answer2 && answer2}
            </Text>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
export default CustomAccordion;
